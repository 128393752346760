import "./SplitView.scss";
import React, { useContext } from "react";
import { APPSTATE } from "../utils/AppState";
import { AppContext } from "../context/AppContext";

// Split pane and Compare slider
import SplitPane from "react-split-pane";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
} from "react-compare-slider";

// Components
import Map from "../components/Map";
import UnityModel from "../components/UnityModel";
import StreetView from "../components/StreetView";

const displayCompareSlider = {
  backgroundColor: "white",
  border: 0,
  color: "#1A2A2F",
};

const hideCompareSlider = {
  display: "none",
  backgroundColor: "white",
  border: 0,
  color: "#1A2A2F",
};

export default function SplitView({ unityProvider }) {
  const {
    setSplitSize,
    appState,
    compareSlider,
    setCompareSlider,
    currentLongLat,
    currentCoordinate,
    splitSize,
  } = useContext(AppContext);

  return (
    <div className="compare-slider">
      <ReactCompareSlider
        handle={
          appState === APPSTATE.COMPARE ? (
            <ReactCompareSliderHandle
              buttonStyle={displayCompareSlider}
              linesStyle={{ opacity: 0.5 }}
            />
          ) : (
            <ReactCompareSliderHandle
              buttonStyle={hideCompareSlider}
              linesStyle={{ opacity: 0.0, display: "none" }}
            />
          )
        }
        onlyHandleDraggable={true}
        position={compareSlider}
        onPositionChange={(percentage) => {
          setCompareSlider(percentage);
        }}
        // UNITY / MAP
        itemOne={
          <div className="split-container">
            <SplitPane
              split="vertical"
              onDragFinished={(size) => {
                setSplitSize(`${parseInt((size / window.innerWidth) * 100)}%`);
              }}
              size={splitSize}
              primary="second"
              resizerClassName="pane-resizer"
              allowResize={true}
              defaultSize={splitSize}
            >
              <Map />
              <UnityModel
                userInterfaceEnabled={true}
                unityProvider={unityProvider}
              />
            </SplitPane>
          </div>
        }
        // STREETVIEW COMPARE SLIDER
        itemTwo={
          <div className="streetview">
            {appState === APPSTATE.COMPARE && (
              <StreetView
                lat={currentLongLat.lat}
                long={currentLongLat.long}
                heading={currentCoordinate.heading}
                asModal={false}
              />
            )}
          </div>
        }
      />
    </div>
  );
}
