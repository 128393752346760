export const markerSymbol = {
  type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
  color: [0, 94, 122],
  outline: {
    color: [255, 255, 255],
    width: 1,
  },
};

export const locationSymbol = {
  type: "picture-marker", // autocasts as new SimpleMarkerSymbol()
  url: process.env.PUBLIC_URL + "/position_marker.svg",
  height: "40px",
  width: "34px",
  angle: 90,
};

export const lineSymbol = {
  type: "simple-line", // autocasts as new SimpleLineSymbol()
  color: [0, 139, 209],
  width: 4,
  cap: "butt",
};

export const lineSymbolThin = {
  type: "simple-line", // autocasts as new SimpleLineSymbol()
  color: [25, 70, 84],
  width: 1,
  cap: "butt",
};

export const dashedLineSymbol = {
  type: "simple-line", // autocasts as new SimpleFillSymbol
  style: "short-dash",
  color: [0, 139, 209],
  width: 4,
  cap: "butt",
  join: "round",
};

export const polylinePopupTemplate = {
  title: "{Name}",
  content: [
    {
      type: "fields",
      fieldInfos: [
        {
          fieldName: "name",
        },
        {
          fieldName: "width",
          label: "width (m)",
        },
        {
          fieldName: "length",
          label: "length (m)",
        },
      ],
    },
  ],
};

export const rendererFillOutlined = {
  type: "simple", // autocasts as new SimpleRenderer()
  symbol: {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [0, 94, 122, 0.2],
    outline: {
      // autocasts as new SimpleLineSymbol()
      width: 1,
      color: "white",
    },
  },
};

export const rendererFill = {
  type: "simple", // autocasts as new SimpleRenderer()
  symbol: {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [0, 94, 122, 0.7],
    outline: {
      // autocasts as new SimpleLineSymbol()
      width: 1,
      color: [0, 94, 122, 1],
    },
  },
};

// Styling for Open Data layer: Current Situation
export const rendererFillCurrentSituation = {
  type: "unique-value", // autocasts as new SimpleRenderer()
  field: "verhardingssoort",
  defaultSymbol: {
    type: "simple-fill",
    color: [191, 204, 212, 0.9],
    outline: {
      // autocasts as new SimpleLineSymbol()
      width: 1,
      color: [191, 204, 212, 1],
    },
  },
  uniqueValueInfos: [
    {
      value: "tegels grijs",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [169, 186, 194, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [169, 186, 194, 1],
        },
      },
    },
    {
      value: "asfalt rood",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [218, 142, 147, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [218, 142, 147, 1],
        },
      },
    },
    {
      value: "asfaltbeton",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [121, 144, 154, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [121, 144, 154, 1],
        },
      },
    },
    {
      value: "dicht asfaltbeton",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [121, 144, 154, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [121, 144, 154, 1],
        },
      },
    },
    {
      value: "oppervlaktebehandeling",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [121, 144, 154, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [121, 144, 154, 1],
        },
      },
    },
    {
      value: "gebakken waalformaat",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [184, 161, 148, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [184, 161, 148, 1],
        },
      },
    },
    {
      value: "gebakken keiformaat",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [184, 161, 148, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [184, 161, 148, 1],
        },
      },
    },
    {
      value: "gebakken dikformaat",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [133, 122, 115, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [133, 122, 115, 1],
        },
      },
    },
    {
      value: "Overige",
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [184, 161, 148, 0.9],
        outline: {
          // autocasts as new SimpleLineSymbol()
          width: 1,
          color: [184, 161, 148, 1],
        },
      },
    },
  ],
};
