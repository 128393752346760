// All events fired from Unity
export const EVENTS = {
  camera_transform_update: "CameraTransformUpdate",
  unity_initialized: "UnityInitialized",
  materials_updated: "MaterialsUpdated",
  send_select_signal: "SendSelectSignal",
};

// ALL input methods available in the InputController class in Unity
export const INPUT_METHODS = {
  set_camera_position: "SETCAMERAPOSITION",
  set_camera_rotation: "SETCAMERAROTATION",
  select_profile: "SELECTPROFILE",
  open_road: "OPENROAD",
  debug: "DEBUG",
};

// All Profiles (named) available in Unity
export const PROFILES = ["Profiel-A", "Profiel-I", "Profiel-I1", "Profiel-J1"];

export const projectSetup = {
  appName: "Infrashaper Eindhoven",
  pathToUnityBuild: "unitybuild/unitybuild",
  // Start Coordinate of the Camera
  startCoordinate: {
    x: 161161.98449241358,
    y: 383221.66275389865,
    heading: 125,
  },
  startLatLong: { 
    long: 5.47146,
    lat: 51.43534,
  },

  // Main Spline (used for project init)]
  splines: [
    {
      id: "Keizersgracht",
      path: [
        [161161.98449241358,383221.66275389865],
        [161176.86733467926,383209.35960429237],
        [161190.95642535744,383194.67519992357],
        [161231.83463211387,383152.407927889],
        [161271.57267436155,383110.81830996042],
        [161308.05022501285,383066.71963878517],
        [161321.84165884572,383049.009056489],
        [161370.2514454402,382987.43454052426],
        [161390.43264780147,382962.18894202815],
        [161408.73854378826,382936.64006280538],
        [161422.18271130172,382915.655255211],
        [161448.27729474084,382873.31780952908],
        [161465.83904861435,382844.74275237892],
        [161473.8757834378,382833.431792257],
        [161480.72189088003,382825.09740058822]
      ],
      attributes: {
        "name": "Keizersgracht",
        "width": "28",
        "length": "1025",
      },
      camtransform: {
        "position": [161161.98449241358,383221.66275389865,2],
        "rotation": [0,0,125]
      }
  },
  {
    id: "Mecklenburgstraat",
    path: [
      [160850.96962611939,382913.373402641],
      [160774.85607798139,382873.20318827243],
      [160733.77943332811,382850.97814382234],
      [160725.94113640153,382845.81875850365],
      [160720.78175108277,382841.94921951456],
      [160652.41989560911,382779.14362515335],
      [160626.42453111839,382755.23185857991]
    ],
    attributes: {
      "name": "Mecklenburgstraat",
      "width": "28",
      "length": "1025",
    },
    camtransform: {
      "position": [161161.98449241358,383221.66275389865,2],
      "rotation": [0,0,125]
    }
  },
  {
    id: "Grote berg",
    path: [
      [160987.97837922649,382914.37905187509],
      [161003.65497307968,382922.56461512123],
      [161007.92138786253,382924.69782251259],
      [161013.47764897504,382926.831029904],
      [161028.55892913754,382929.2618941408],
      [161047.26170091808,382931.29588258377],
      [161060.606649483,382933.03221418132],
      [161069.53635484239,382934.57010788214],
      [161077.82113703695,382936.95136264467],
      [161099.64930569328,382944.44239325175],
      [161134.47515659491,382957.14241865184],
      [161165.82834430129,382969.59439668083],
      [161202.04326048109,382984.129972627],
      [161219.85306172568,382992.2659263988],
      [161237.61325349603,383000.27785648534],
      [161251.95039154546,383007.84330130374],
      [161267.89983750696,383016.57456876693],
      [161276.65590970661,383022.67653409584],
      [161281.02154343788,383026.49646361079],
      [161293.32469304418,383036.86484372255],
      [161302.45283630045,383044.4302885409]
    ],
    attributes: {
      "name": "Grote berg",
      "width": "28",
      "length": "1025",
    },
    camtransform: {
      "position": [161161.98449241358,383221.66275389865,2],
      "rotation": [0,0,125]
    }
  },
  {
    id: "Willem de Zwijgerstraat",
    path: [
      [160653.07661863169,382955.06769937353],
      [160659.66227633425,382954.62121410557],
      [160670.26630144857,382953.92668146663],
      [160683.90890685879,382952.8972848765],
      [160699.23823439237,382951.91749776079],
      [160721.92216647911,382950.3175922173],
      [160722.26943279864,382950.3175922173],
      [160736.74412959345,382949.567395882],
      [160751.29210790835,382948.38917086931],
      [160767.76245334905,382947.27295769932],
      [160792.2447288761,382945.55522965471],
      [160799.90939264302,382944.84829464706],
      [160802.4642805653,382944.91030648985],
      [160803.84094347488,382945.18315859808],
      [160805.78811533796,382946.28696939943]
    ],
    attributes: {
      "name": "Willem de Zwijgerstraat",
      "width": "28",
      "length": "1025",
    },
    camtransform: {
      "position": [161161.98449241358,383221.66275389865,2],
      "rotation": [0,0,125]
    }
  },
  {
    id: "Mauritsstraat",
    path: [
      [160857.46098318542,382926.2193034495],
      [160839.83503443751,382941.86888478731],
      [160806.64198703706,382979.20649154764],
      [160733.02225505011,383061.01620820054],
      [160732.04805829751,383061.9124469182],
      [160688.43637389209,383113.11460559542]
    ],
    attributes: {
      "name": "Mauritsstraat",
      "width": "28",
      "length": "1025",
    },
    camtransform: {
      "position": [161161.98449241358,383221.66275389865,2],
      "rotation": [0,0,125]
    }
  }]
};
