import React from "react";

export default function useToggle(initialState) {
  const [isToggled, setIsToggled] = React.useState(initialState);
  const toggle = React.useCallback(
    () => setIsToggled((state) => !state),
    [setIsToggled]
  );

  return [isToggled, toggle];
}
