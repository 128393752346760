import proj4 from "proj4";

// Converts RD coordinate to WSG84 projection (lat / long) used for e.g. Google Streetview
const latlongProjection = "+proj=longlat +datum=WGS84 +no_defs";
const rdNewProjection =
  "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs";

export function convertToLongLat(x, y) {
  let c = proj4(rdNewProjection, latlongProjection, {
    x: x,
    y: y,
  });
  return c;
}
