import React from "react";
// IMPROVE: this increases bundle size by loading all icons by default
import * as Icon from "@mui/icons-material";
import "./SidebarButton.scss";

/**
 * A button for the <Sidebar /> component with an optional icon.
 *
 * @param {Object} props - The props for the component
 * @param {boolean} props.active - Whether the button is active
 * @param {string} props.icon - The name of the icon to display
 * @param {string} props.borderColor - The border color of the button as css property
 * @param {function} props.onClick - A click event handler for the button
 *
 * @returns {JSX.Element} - The component
 */
export default function SidebarButton({ active, icon, borderColor, onClick }) {
  const IconError = Icon["Error"];
  const IconByProp = Icon[String(icon)];

  if (typeof IconByProp !== "undefined") {
    return (
      <div
        className={active ? "sidebar-item active" : "sidebar-item"}
        onClick={onClick}
        style={{ borderColor: borderColor !== null && borderColor }}
      >
        <IconByProp className="icon" />
      </div>
    );
  } else {
    console.error(
      `Material design icon with name: ${icon} not found! Please check: @mui/icons-material library`
    );
    return (
      <div
        className={active ? "sidebar-item active" : "sidebar-item"}
        onClick={onClick}
      >
        <IconError className="icon error" />
      </div>
    );
  }
}
