import React from "react";
import AppContextProvider from "../context/AppContext";
import UnityContextProvider from "../context/UnityContext";
import EsriContextProvider from "../context/EsriContext";

import App from "../views/App";

export default function AppContext() {
  return (
    <AppContextProvider>
      <UnityContextProvider>
        <EsriContextProvider>
          <App />
        </EsriContextProvider>
      </UnityContextProvider>
    </AppContextProvider>
  );
}
