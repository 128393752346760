import { useEffect } from "react";
import { showLocationOnMap } from "../utils/EsriMap";

/**
 * This Hook updates the location of the Position Marker on the ESRI map
 * @param {*} view the Esri View Instance
 * @param {*} currentCoordinate the Current Coordinate
 */
export function useUpdatePositionMarker(view, currentCoordinate) {
  useEffect(() => {
    if (view) {
      showLocationOnMap(
        view,
        currentCoordinate.x,
        currentCoordinate.y,
        currentCoordinate.heading
      );
    }
  }, [
    currentCoordinate.heading,
    currentCoordinate.x,
    currentCoordinate.y,
    view,
  ]);
}
