import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { EsriContext } from "../context/EsriContext";
import { UnityContext } from "../context/UnityContext";
import { INPUT_METHODS } from "../projectSetup";
import "./Sidebar.scss";

import SidebarButton from "./SidebarButton";

// ESRI
import {
  drawPolyline,
  stopDrawing,
  toggleLayerWidget,
  toggleBaseMap,
} from "../utils/EsriMap";

function Sidebar() {
  const appContext = useContext(AppContext);
  const {
    view,
    photoBasemap,
    togglePhotoBasemap,
    layerPanel,
    toggleLayerPanel,
    drawing,
    toggleDrawing,
  } = useContext(EsriContext);
  const unityContext = useContext(UnityContext);

  const resetDrawing = () => {
    toggleDrawing();
    stopDrawing(view);
  };

  // TODO: remove -> implement pop-up to refresh spline after selection on the map
  const refreshSpline = () => {
    unityContext.sendMessageToUnity(INPUT_METHODS.open_road, appContext.spline);
  };

  return (
    <div className="sidebar">
      <div className="top">
        {appContext.appState !== "explore" && (
          <>
            <SidebarButton
              icon={photoBasemap ? "MapOutlined" : "Public"}
              active={photoBasemap}
              onClick={() => {
                if (view) {
                  togglePhotoBasemap();
                  toggleBaseMap();
                }
              }}
            />

            <SidebarButton
              icon="LayersOutlined"
              active={layerPanel}
              onClick={() => {
                if (view) {
                  toggleLayerPanel();
                  toggleLayerWidget(view, !layerPanel);
                }
              }}
            />
          </>
        )}

        {/*appContext.appState !== "compare" && (
          <SidebarButton
            icon="Streetview"
            active={appContext.streetViewModal}
            onClick={() => appContext.toggleStreetViewModal()}
          />
        )*/}
        {appContext.appState === "shape" && !drawing && (
          <SidebarButton
            icon="CreateOutlined"
            onClick={() => {
              toggleDrawing();
              drawPolyline(view);
            }}
          />
        )}

        {appContext.appState === "shape" && drawing && (
          <SidebarButton
            icon="DeleteOutlined"
            onClick={() => {
              resetDrawing();
            }}
          />
        )}

        {appContext.appState === "analyze" && (
          <SidebarButton
            icon="AnalyticsOutlined"
            active={appContext.quantitiesWindow}
            onClick={() => {
              appContext.toggleQuantitiesWindow();
            }}
          />
        )}
      </div>

      <div className="bottom">
        <SidebarButton
          icon="Settings"
          borderColor="var(--wb-signal-red)"
          active={appContext.debuggingMode}
          onClick={() => {
            appContext.toggleDebuggingMode();
          }}
        />
        <SidebarButton
          icon="Autorenew"
          borderColor="var(--wb-signal-green)"
          active={true}
          onClick={() => {
            refreshSpline();
          }}
        />
      </div>
    </div>
  );
}

export default Sidebar;
