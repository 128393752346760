import React, {
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { EsriContext } from "../context/EsriContext";
import { AppContext } from "../context/AppContext";
import { UnityContext } from "../context/UnityContext";
import { loadMap } from "../utils/EsriMap";
import { INPUT_METHODS, projectSetup } from "../projectSetup";
import "../components/Map.scss";
import { useUpdatePositionMarker } from "../hooks/useUpdatePositionMarker";
import { useMapViewClickListener } from "../hooks/useMapViewClickListener";

export default function Map() {
  const mapRef = useRef();
  const { view, setView } = useContext(EsriContext);
  const { updateCurrentCoordinate, updateSplinePathId, currentCoordinate } =
    useContext(AppContext);
  const { sendMessageToUnity } = useContext(UnityContext);

  // Load and initialize map
  useEffect(() => {
    loadMap(mapRef.current, [projectSetup.splines], {
      x: projectSetup.startCoordinate.x,
      y: projectSetup.startCoordinate.y,
    }).then((view) => {
      setView(view);
    });

    return () => {
      if (view) {
        setView(null);
        view.destroy();
        console.warn("MAPVIEW | Esri View destroyed");
      }
    };
  }, []); // !! empty

  useUpdatePositionMarker(view, currentCoordinate);

  const setPositionUnityAndContext = useCallback(
    (x, y) => {
      sendMessageToUnity(INPUT_METHODS.set_camera_position, { x: x, y: y });
      updateCurrentCoordinate({ x: x, y: y });
    },
    [sendMessageToUnity, updateCurrentCoordinate]
  );

  const updateSpline = useCallback(
    (path, id) => {
      updateSplinePathId(path, id);
    },
    [updateSplinePathId]
  );

  // At click on map
  const eventListener = useMemo(() => {
    return function (event) {
      // Right Mouse button
      if (event.button === 2) {
        setPositionUnityAndContext(event.mapPoint.x, event.mapPoint.y);
      }
      view.hitTest(event).then((response) => {
        if (response.results[0].graphic.geometry !== null) {
          // !drawing
          if (response.results[0].graphic.attributes.id !== "drawing") {
            if (response.results[0].graphic.geometry.type === "polyline") {
              // TODO: handle click on Polyline => e.g. load this spline in Unity, or show additional UI element
              // TODO: handle update of spline / id in pop-up (so it can also be cancelled or ignored)
              updateSpline(
                response.results[0].graphic.geometry.paths[0],
                projectSetup.splines[0].id
              );
            }
          }
        }
        if (response.results[0].graphic.attributes.id === "drawing") {
          updateSpline(response.results[0].graphic.geometry.paths[0], "Sketch");
        }
      });
    };
  }, [setPositionUnityAndContext, updateSpline, view]);

  // Listen to clicks on the map and do things
  useMapViewClickListener(view, eventListener);

  return <div ref={mapRef} className="webmap"></div>;
}
