import React from "react";
import ReactDOM from "react-dom/client";
import "../src/index.scss";
import AppContextWrapper from "../src/utils/AppContextWrapper";
import { setDefaultOptions } from "esri-loader";
import "bootstrap/dist/css/bootstrap.min.css";

// configure esri-loader to lazy load the CSS
// the fisrt time any react-arcgis components are rendered
setDefaultOptions({ css: true });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppContextWrapper />
  </React.StrictMode>
);
