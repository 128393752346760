import { trigger } from "../utils/Events";
import { EVENTS } from "../projectSetup";
// TODO: evaluate / implementation without events
// TODO: write docs

//Method for handling events and parsing the received data from Unity
export function handleUnityEvents(jsonMessage) {
  try {
    const parsedJson = JSON.parse(jsonMessage);

    if (Object.values(EVENTS).includes(parsedJson.type)) {
      trigger(parsedJson.type, parsedJson.message);
    } else {
      console.error(
        `HandleUnityMessage: no implementation for ${parsedJson.type} event.`
      );
    }
  } catch (error) {
    console.log(error);
  }
}
