import "./QuantityOverview.scss";

function QuantityOverview({ quantities, profileName }) {
  return (
    <div className="quantity-wrapper">
      <div className="card-wrapper">
        <h5 className="wb-productive-heading5 capitals">
          Quantities | <b>{profileName}</b>
        </h5>
        <div className="visual-wrapper">
          {quantities.length > 0 &&
            quantities.map((q) => {
              return (
                <p key={q.MaterialType}>
                  {q.MaterialType}:{" "}
                  {q.MaterialType !== "Bomen"
                    ? parseInt(q.Quantity)
                    : parseInt(q.Quantity)}
                  {q.MaterialType !== "Bomen" && (
                    <>
                      m<sup>2</sup>
                    </>
                  )}
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default QuantityOverview;
