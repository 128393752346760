import "./App.scss";
import React, { useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { UnityContext } from "../context/UnityContext";
import { APPSTATE } from "../utils/AppState";
import { handleUnityEvents } from "../utils/UnityHandler.js";

// Views
import StartView from "./StartView";
import SplitView from "../views/SplitView";

// UI Components
import Container from "react-bootstrap/Container";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ModalContainer from "../components/ModalContainer";

// Components
import StreetView from "../components/StreetView";
import QuantityOverview from "../components/QuantityOverview";

export default function App() {
  const appContext = useContext(AppContext);
  const {
    unityProvider,
    loadingProgression,
    addEventListener,
    removeEventListener,
    loadingState,
  } = useContext(UnityContext);

  useEffect(() => {
    addEventListener("SendToReact", handleUnityEvents);
    return () => {
      removeEventListener("SendToReact", handleUnityEvents);
    };
  }, [addEventListener, removeEventListener]);

  return (
    <>
      {
        <div className="App">
          <Container fluid>
            {loadingProgression * 100 < 101 && (
              <StartView
                loading={loadingState}
                progression={loadingProgression * 100}
              />
            )}
            <Header />
            <Sidebar />

            {/* Main App | Unity / Map / Compare-slider */}
            <SplitView unityProvider={unityProvider} />

            {/* Quantities window */}
            {appContext.appState === APPSTATE.ANALYZE &&
              appContext.quantitiesWindow && (
                <div className="floating-quantity-container">
                  <QuantityOverview
                    profileName={appContext.profile}
                    quantities={appContext.quantities}
                  />
                </div>
              )}

            {/* Streetview Modal */}
            {appContext.streetViewModal && (
              <ModalContainer>
                <StreetView
                  lat={appContext.currentLongLat.lat}
                  long={appContext.currentLongLat.long}
                  heading={appContext.currentCoordinate.heading}
                  asModal={true}
                />
              </ModalContainer>
            )}
          </Container>
        </div>
      }
    </>
  );
}
