import { createContext, useState } from "react";
import { APPSTATE } from "../utils/AppState";
import useToggle from "../hooks/useToggle";
import { projectSetup } from "../projectSetup";
import { convertToLongLat } from "../utils/CoordinateConverter";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [appState, setAppState] = useState(APPSTATE.EXPLORE);

  const [currentCoordinate, setCurrentCoordinate] = useState(
    projectSetup.startCoordinate
  );
  const [currentLongLat, setCurrentLongLat] = useState(
    projectSetup.startLatLong
  );

  // Update and convert coordinates in both systems
  const updateCurrentCoordinate = (currentCoordinate) => {
    setCurrentCoordinate((prevState) => ({
      ...prevState,
      x: currentCoordinate.x,
      y: currentCoordinate.y,
      heading: currentCoordinate.heading,
    }));
    let convertedCoordinates = convertToLongLat(
      parseFloat(currentCoordinate.x),
      parseFloat(currentCoordinate.y)
    );
    setCurrentLongLat((prevState) => ({
      ...prevState,
      long: convertedCoordinates.x,
      lat: convertedCoordinates.y,
    }));
  };

  const [spline, setSpline] = useState(projectSetup.splines[0]);
  const [profile, setProfile] = useState("Profile1");

  // update spline path and id
  const updateSplinePathId = (spline, id) => {
    setSpline((prevState) => ({
      ...prevState,
      path: spline,
      id: id,
    }));
  };

  const [compareSlider, setCompareSlider] = useState(100);
  const [splitSize, setSplitSize] = useState("50%");

  // UI State
  const [streetViewModal, toggleStreetViewModal] = useToggle(false);
  const [quantities, setQuantities] = useState({});
  const [quantitiesWindow, toggleQuantitiesWindow] = useToggle(true);
  const [debuggingMode, toggleDebuggingMode] = useToggle(false);

  return (
    <AppContext.Provider
      value={{
        currentCoordinate,
        setCurrentCoordinate,
        currentLongLat,
        setCurrentLongLat,
        updateCurrentCoordinate,
        appState,
        setAppState,
        spline,
        setSpline,
        updateSplinePathId,
        profile,
        setProfile,
        splitSize,
        setSplitSize,
        compareSlider,
        setCompareSlider,
        streetViewModal,
        toggleStreetViewModal,
        quantitiesWindow,
        toggleQuantitiesWindow,
        quantities,
        setQuantities,
        debuggingMode,
        toggleDebuggingMode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
