import { createContext } from "react";
import useToggle from "../hooks/useToggle";
import { projectSetup } from "../projectSetup";
import { useUnityContext } from "react-unity-webgl";

export const UnityContext = createContext();

export const UnityContextProvider = ({ children }) => {
  const [loadingState, toggleLoadingState] = useToggle(true);

  const {
    unityProvider,
    loadingProgression,
    addEventListener,
    removeEventListener,
    sendMessage,
  } = useUnityContext({
    loaderUrl: `${projectSetup.pathToUnityBuild}.loader.js`,
    dataUrl: `${projectSetup.pathToUnityBuild}.data`,
    frameworkUrl: `${projectSetup.pathToUnityBuild}.framework.js`,
    codeUrl: `${projectSetup.pathToUnityBuild}.wasm`,
  });

  // Send a formatted message to Unity
  const sendMessageToUnity = (
    type,
    message,
    publicFunction = "SendToUnity",
    gameObject = "InputController"
  ) => {
    // IMPROVE: check INPUT_METHODS for correct input method, and log error accordingly
    let messageToSend = { type: type, message: message };
    sendMessage(gameObject, publicFunction, JSON.stringify(messageToSend));
  };

  return (
    <UnityContext.Provider
      value={{
        unityProvider,
        loadingProgression,
        addEventListener,
        removeEventListener,
        sendMessageToUnity,
        loadingState,
        toggleLoadingState,
      }}
    >
      {children}
    </UnityContext.Provider>
  );
};

export default UnityContextProvider;
