import React, { useState, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  StreetViewPanorama,
} from "@react-google-maps/api";
import "../components/StreetView.scss";
import PropTypes from "prop-types";

/**
 * A component to display a streetview image for the given location
 *
 * @param {Number} lat - The latidute of the streetview image
 * @param {Number} long - The longitude of the streetview image
 * @param {Number} heading - The heading of the view: 0 = North, 180 = South.
 * @returns {JSX.Element}
 */
function StreetView({ lat, long, heading, asModal }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_APIKEY,
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    // Example: do something with map instance
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={
        !asModal ? "streetview-container-modal" : "streetview-containter-split"
      }
      center={{
        lat: lat,
        lng: long,
      }}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <StreetViewPanorama
        visible={true}
        options={{
          position: {
            lat: parseFloat(lat),
            lng: parseFloat(long),
          },
          pov: { heading: parseInt(heading), pitch: 0 },
          zoom: 1,
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

StreetView.propTypes = {
  lat: PropTypes.number,
  long: PropTypes.number,
  heading: PropTypes.number,
};

export default React.memo(StreetView);
