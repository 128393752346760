import { createContext, useState } from "react";
import useToggle from "../hooks/useToggle";

export const EsriContext = createContext();

export const EsriContextProvider = ({ children }) => {
  const [view, setView] = useState();
  const [drawing, toggleDrawing] = useToggle(false);
  const [layerPanel, toggleLayerPanel] = useToggle(false);
  const [photoBasemap, togglePhotoBasemap] = useToggle(false);

  return (
    <EsriContext.Provider
      value={{
        view,
        setView,
        drawing,
        toggleDrawing,
        layerPanel,
        toggleLayerPanel,
        photoBasemap,
        togglePhotoBasemap,
      }}
    >
      {children}
    </EsriContext.Provider>
  );
};

export default EsriContextProvider;
