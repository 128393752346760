import "./ModalContainer.scss";
import CloseButton from "react-bootstrap/CloseButton";

function ModalContainer({ children }) {
  return (
    <div className="modal-container">
      {children}
      <CloseButton className="modal-close-button"></CloseButton>
    </div>
  );
}

export default ModalContainer;
