import { loadModules } from "esri-loader";
import {
  locationSymbol,
  lineSymbol,
  lineSymbolThin,
  polylinePopupTemplate,
  rendererFillCurrentSituation,
  rendererFillOutlined,
  dashedLineSymbol,
} from "../utils/EsriMapStyles";

// NOTE: module, not global
let _Graphic;
let _Polyline;
let _Draw;
let _FeatureLayer;
let _GraphicsLayer;

let _geometryEngine;
let _layerList;
let _locationLayer;
let _drawingLayer;
let _featureLayer;
let _offsetLayer;
let _satelliteLayer;

export const spatialReference = {
  wkid: 28992, // RD-NEW
};

/**
 * Initialize ESRI map instance
 * @param {htmlelement} element
 * @param {Array} splines - see projectSetup.js .splines
 * @param {Object} startCoordinate x: / y:
 * @returns
 */
export function loadMap(element, splines, startCoordinate) {
  return loadModules(
    [
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/views/draw/Draw",
      "esri/config",
      "esri/Basemap",
      "esri/layers/VectorTileLayer",
      "esri/layers/WFSLayer",
      "esri/layers/WMTSLayer",
      "esri/layers/FeatureLayer",
      "esri/layers/GroupLayer",
      "esri/layers/GraphicsLayer",
      "esri/geometry/Polyline",
      "esri/geometry/geometryEngine",
      "esri/widgets/LayerList",
      "esri/layers/Layer",
    ],
    {
      css: true,
    }
  ).then(
    ([
      Map,
      MapView,
      Graphic,
      Draw,
      esriConfig,
      Basemap,
      VectorTileLayer,
      WFSLayer,
      WMTSLayer,
      FeatureLayer,
      GroupLayer,
      GraphicsLayer,
      Polyline,
      geometryEngine,
      LayerList,
      Layer,
    ]) => {
      if (!element) {
        return;
      }
      esriConfig.apiKey = ""; // API KEY

      // hold onto Module for later use
      _Graphic = Graphic;
      _Polyline = Polyline;
      _geometryEngine = geometryEngine;
      _Draw = Draw;
      _FeatureLayer = FeatureLayer;
      _GraphicsLayer = GraphicsLayer;

      // Base Map
      const vectorTileLayerWB = new VectorTileLayer({
        url: "https://tiles.arcgis.com/tiles/nSZVuSZjHpEZZbRo/arcgis/rest/services/Topo_RD/VectorTileServer",
        opacity: 1,
      });

      // HR Aireal RD foto
      _satelliteLayer = new WMTSLayer({
        url: "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0",
        activeLayer: {
          id: "2021_orthoHR",
        },
        visible: false,
      });

      // TODO: refactor / modularize / Grouplayer
      // const openDataBAG = new WFSLayer({
      //   url: "https://data.haarlem.nl/geoserver/wfs", // URL to your WFS endpoint
      //   name: "gemeentehaarlem:bag_object", // Featuretype
      //   title: "BAG Objecten (Haarlem Open Data)",
      //   visible: true,
      //   renderer: rendererFill,
      // });

      const openDataBRK = new WFSLayer({
        url: "https://data.haarlem.nl/geoserver/wfs", // URL to your WFS endpoint
        name: "gemeentehaarlem:kadastrale_kaart", // Featuretype
        title: "BRK (Haarlem Open Data)",
        definitionExpression: "eigenaar <> 'Gemeente Haarlem'", // TODO: fix for name
        visible: true,
        renderer: rendererFillOutlined,
      });

      const openDataErfGrens = new WFSLayer({
        url: "https://data.haarlem.nl/geoserver/wfs", // URL to your WFS endpoint
        name: "gemeentehaarlem:bor_wegvakonderdeel", // Featuretype
        title: "Huidige situatie (Haarlem Open Data)",
        visible: false,
        renderer: rendererFillCurrentSituation,
      });

      const openDataGroupLayer = new GroupLayer({
        title: "Haarlem Open Data",
        visible: false,
      });
      openDataGroupLayer.addMany([openDataErfGrens, openDataBRK]);

      // create the Map
      const map = new Map({
        basemap: new Basemap({
          baseLayers: [vectorTileLayerWB, _satelliteLayer],
        }),
        spatialReference,
        layers: [openDataGroupLayer],
      });

      // show the map at the element
      let view = new MapView({
        map,
        container: element,
        spatialReference,
        center: {
          x: startCoordinate.x,
          y: startCoordinate.y,
          spatialReference,
        },
        zoom: 10,
        popup: {
          defaultPopupTemplateEnabled: true, // popup will be enabled on the wfslayer
        },
      });

      // Init layerlist and hold on for external reference (toggle)
      _layerList = new LayerList({
        view: view,
      });

      // wait for the view to load
      return view.when(() => {
        showItemsOnMap(view, splines);

        // create location layer
        _locationLayer = new _GraphicsLayer({
          title: "Location",
          listMode: "hide",
          visible: true,
        });

        // create a sketch layer
        _drawingLayer = new _GraphicsLayer({
          title: "Sketched Profiles",
          visible: true,
        });

        view.map.add(_featureLayer);
        view.map.add(_offsetLayer);
        view.map.add(_drawingLayer);
        view.map.add(_locationLayer);

        // return a reference to the view
        return view;
      });
    }
  );
}

// TODO: move to separate module EsriMapUtils.js
export function showItemsOnMap(view, mapPolylines) {
  if (!_Graphic) {
    throw new Error("You must load a map before creating new graphics");
  }
  if (!view || !view.ready) {
    return;
  }

  let featureData = [];
  let offsetData = [];

  mapPolylines[0].forEach((line) => {
    let polyline = {
      type: "polyline", // autocasts as new Polyline()
      paths: line.path,
      spatialReference: spatialReference,
    };

    let polylineGraphic = new _Graphic({
      geometry: polyline,
      // symbol: lineSymbol,
      attributes: line.attributes,
    });

    featureData.push(polylineGraphic);

    // TODO: Refactor
    let offsetPolyline = new _Polyline({
      paths: line.path,
      spatialReference: spatialReference,
    });

    let offset = _geometryEngine.offset(offsetPolyline, 11);
    let offsetNegative = _geometryEngine.offset(offsetPolyline, -11);

    let polylineGraphicOffset = new _Graphic({
      geometry: offset,
    });

    let polylineGraphicOffsetNegative = new _Graphic({
      geometry: offsetNegative,
    });

    offsetData.push(polylineGraphicOffset);
    offsetData.push(polylineGraphicOffsetNegative);
    // TODO: END Refactor
  });

  _featureLayer = new _FeatureLayer({
    title: "Predefined Profiles",
    source: featureData,
    popupTemplate: polylinePopupTemplate,
    fields: [
      {
        name: "id",
        alias: "id",
        type: "oid",
      },
      {
        name: "name",
        alias: "name",
        type: "string",
      },
      {
        name: "width",
        alias: "width",
        type: "string",
      },
      {
        name: "length",
        alias: "length",
        type: "string",
      },
    ],
    renderer: {
      // overrides the layer's default renderer
      type: "simple",
      symbol: lineSymbol,
    },
  });

  // TODO: pass parameter to set width based on Unity event
  _offsetLayer = new _FeatureLayer({
    title: "Wegprofiel Offset",
    source: offsetData,
    objectIdField: "id",
    renderer: {
      // overrides the layer's default renderer
      type: "simple",
      symbol: lineSymbolThin,
    },
    visible: false, // default off
  });
}

/**
 * Shows location marker on the map (in RD coordinates)
 * @param {object} view
 * @param {float} x
 * @param {float} y
 * @param {float} r
 * @returns
 */
export function showLocationOnMap(view, x, y, r) {
  if (!_Graphic) {
    throw new Error("You must load a map before creating new graphics");
  }
  if (!view || !view.ready) {
    return;
  }

  // show location
  let currentLocationPoint = {
    type: "point",
    x: x,
    y: y,
    spatialReference: spatialReference,
  };

  // SET ANGLE
  locationSymbol.angle = r;

  let locationGraphic = new _Graphic({
    geometry: currentLocationPoint,
    symbol: locationSymbol,
  });

  _locationLayer.removeAll();
  _locationLayer.add(locationGraphic);
}

/**
 * Remove all graphics layers from given view
 */
export function removeAllGraphics() {
  _drawingLayer.removeAll();
}

/**
 * Toggles the state of the layer widged for a given view
 * @param {object} view
 * @param {bool} state
 */
export function toggleLayerWidget(view, state) {
  if (!_Graphic) {
    throw new Error("You must load a map before showing layer widget");
  }
  if (state) {
    view.ui.add(_layerList, {
      position: "top-left",
    });
  } else {
    view.ui.remove(_layerList);
  }
}

/**
 * Toggle between satellite vs map base view
 */
export function toggleBaseMap() {
  _satelliteLayer.visible
    ? (_satelliteLayer.visible = false)
    : (_satelliteLayer.visible = true);
}

let _action;

// TODO: refactor to separate module EsriMapDraw.js
export function drawPolyline(view) {
  if (!_Graphic) {
    throw new Error("You must load a map before creating new graphics");
  }
  if (!view || !view.ready) {
    return;
  }

  const draw = new _Draw({
    view: view,
  });

  // creates and returns an instance of PolyLineDrawAction
  _action = draw.create("polyline");

  // focus the view to activate keyboard shortcuts for sketching
  view.focus();

  // listen polylineDrawAction events to give immediate visual feedback
  _action.on(
    [
      "vertex-add",
      "vertex-remove",
      "cursor-update",
      "redo",
      "undo",
      "draw-complete",
    ],
    updateVertices
  );
  console.log("actions set!");

  // Checks if the last vertex is making the line intersect itself.
  function updateVertices(event) {
    // create a polyline from returned vertices
    if (event.vertices.length > 1) {
      const result = createGraphic(event);

      // if the last vertex is making the line intersects itself,
      // prevent the events from firing
      if (result.selfIntersects) {
        event.preventDefault();
      }
    }
  }

  // create a new graphic presenting the polyline that is being drawn on the view
  function createGraphic(event) {
    const vertices = event.vertices;
    _drawingLayer.removeAll();

    // a graphic representing the polyline that is being drawn
    const graphic = new _Graphic({
      geometry: {
        type: "polyline",
        paths: vertices,
        spatialReference: spatialReference,
      },
      attributes: {
        id: "drawing",
      },
      // TODO: Load from style module
      symbol: dashedLineSymbol,
    });

    // check if the polyline intersects itself.
    const intersectingSegment = getIntersectingSegment(graphic.geometry);

    // Add a new graphic for the intersecting segment.
    if (intersectingSegment) {
      _drawingLayer.addMany([graphic, intersectingSegment]);
    }
    // Just add the graphic representing the polyline if no intersection
    else {
      _drawingLayer.add(graphic);
    }

    // return intersectingSegment
    return {
      selfIntersects: intersectingSegment,
    };
  }

  // function that checks if the line intersects itself
  function isSelfIntersecting(polyline) {
    if (polyline.paths[0].length < 3) {
      return false;
    }
    const line = polyline.clone();

    //get the last segment from the polyline that is being drawn
    const lastSegment = getLastSegment(polyline);
    line.removePoint(0, line.paths[0].length - 1);

    // returns true if the line intersects itself, false otherwise
    return _geometryEngine.crosses(lastSegment, line);
  }

  // Checks if the line intersects itself. If yes, change the last
  // segment's symbol giving a visual feedback to the user.
  function getIntersectingSegment(polyline) {
    if (isSelfIntersecting(polyline)) {
      return new _Graphic({
        geometry: getLastSegment(polyline),
        // TODO: load from module (THIS IS ERROR LINE)
        symbol: {
          type: "simple-line", // autocasts as new SimpleLineSymbol
          width: 4,
          color: [181, 0, 15],
          cap: "butt",
        },
      });
    }
    return null;
  }

  // Get the last segment of the polyline that is being drawn
  function getLastSegment(polyline) {
    const line = polyline.clone();
    const lastXYPoint = line.removePoint(0, line.paths[0].length - 1);
    const existingLineFinalPoint = line.getPoint(0, line.paths[0].length - 1);

    return {
      type: "polyline",
      spatialReference: spatialReference,
      hasZ: false,
      paths: [
        [
          [existingLineFinalPoint.x, existingLineFinalPoint.y],
          [lastXYPoint.x, lastXYPoint.y],
        ],
      ],
    };
  }
}

/**
 * Stop drawing on the view
 * @param {object} view
 * @returns null if view or _action is not instantiated yet
 */
export function stopDrawing(view) {
  if (!_action) {
    throw new Error("You must load a map before creating new graphics");
  }
  if (!view || !view.ready) {
    return;
  }
  _action.complete();
  removeAllGraphics();
}

// const vectorTileLayer = new VectorTileLayer({
//   portalItem: {
//     //id: "52af64a5c1934344ab5a20888d0f4660", // Custom WB map Topo-World
//     id: "d7ef7125435841a2b2b2138cd7a542f5", // Custom WB map RD-new
//   },
//   opacity: 1,
// });
