import { React, useContext } from "react";
import "./Header.scss";
import { APPSTATE } from "../utils/AppState";
import { AppContext } from "../context/AppContext";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/WB_logo.svg";
import Nav from "react-bootstrap/Nav";
import {
  ExploreOutlined,
  CreateOutlined,
  AnalyticsOutlined,
  Compare,
} from "@mui/icons-material";
import { projectSetup } from "../projectSetup";

export default function Header() {
  const appContext = useContext(AppContext);

  const updateStateAndSplit = (state, split) => {
    appContext.setAppState(state);
    appContext.setSplitSize(split);
    // reset compare slider
    appContext.setCompareSlider(100);
  };

  return (
    <Navbar bg="light">
      <Navbar.Brand>
        <img
          href={`${process.env.PUBLIC_URL}/`}
          alt="Witteveen+Bos Logo"
          src={logo}
          height="30"
          className="d-inline-block align-top logo"
        />
        {appContext.debuggingMode ? (
          <b>DEBUG Mode | </b>
        ) : (
          <>
            <b>{projectSetup.appName}</b> | {appContext.spline?.id}{" "}
          </>
        )}

        {/* TODO: make debugger component */}
        {appContext.debuggingMode &&
          `X: ${appContext.currentCoordinate.x} Y: ${appContext.currentCoordinate.y}, long: ${appContext.currentLongLat.long} lat: ${appContext.currentLongLat.lat}`}
      </Navbar.Brand>
      <Nav className="ms-auto">
        {/* EXPLORE */}
        <Nav.Link
          active={appContext.appState === APPSTATE.EXPLORE ? true : false}
          onClick={() => updateStateAndSplit(APPSTATE.EXPLORE, "50%")}
        >
          <ExploreOutlined fontSize="small" className="header-icon" />
          Explore
        </Nav.Link>

        {/* SHAPE */}
        <Nav.Link
          active={appContext.appState === APPSTATE.SHAPE ? true : false}
          onClick={() => updateStateAndSplit(APPSTATE.SHAPE, "40%")}
        >
          <CreateOutlined fontSize="small" className="header-icon" />
          Shape
        </Nav.Link>

        {/* ANALYZE */}
        {/*
        <Nav.Link
          active={appContext.appState === APPSTATE.ANALYZE ? true : false}
          onClick={() => updateStateAndSplit(APPSTATE.ANALYZE, "50%")}
        >
          <AnalyticsOutlined fontSize="small" className="header-icon" />
          Analyze
        </Nav.Link>
        */}
        <Nav.Link
          active={appContext.appState === APPSTATE.COMPARE ? true : false}
          onClick={() => {
            updateStateAndSplit(APPSTATE.COMPARE, "100%");
            appContext.setCompareSlider(50);
          }}
        >
          <Compare fontSize="small" className="header-icon" />
          Compare
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}
