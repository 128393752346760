import React, { useState } from "react";
import "./ProfileSelector.scss";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
// import Form from "react-bootstrap/Form";

// Custom toggle component
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <div
    className="selector-wrapper"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <p className="selector-text"> {children}</p>
  </div>
));

// Custom dropdown menu component with search form
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* Optional Search:
         <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Search"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        /> */}
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

/**
 * A dropdown selector that takes an array of string with available options.
 *
 * @param {string} name - The name displayed in the profile selector
 * @param {function} onSelect - Callback function when dropdown item is selected
 * @param {string} selectedProfile - Current selectedProfile
 * @param {Array} availableProfiles - All available profiles - Array of strings
 *
 * @returns {JSX.Element} - The component
 */

function ProfileSelector({
  name,
  onSelect,
  selectedProfile,
  availableProfiles,
}) {
  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        drop="left"
      >
        {name}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className="dropdown-list">
        {availableProfiles.map((item, index) => {
          return (
            <Dropdown.Item
              eventKey={item}
              active={selectedProfile === item}
              key={item}
            >
              {item}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

ProfileSelector.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  selectedProfile: PropTypes.string,
  availableProfiles: PropTypes.arrayOf(PropTypes.string),
};

export default ProfileSelector;
