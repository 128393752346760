import { useEffect } from "react";

/**
 * This Hook handles the clicks on the ESRI map by passing in an eventhandler
 * @param {*} view the Esri View Instance
 * @param {*} eventListener the Current Coordinate
 */
export function useMapViewClickListener(view, eventListener) {
  useEffect(() => {
    if (view) {
      const listener = view.on("click", eventListener);
      return () => {
        listener.remove();
      };
    }
  }, [eventListener, view]);
}
