import "./StartView.scss";

function StartScreen(props) {
  return (
    <>
      {props.loading && (
        <div className="loading-screen-wrapper">
          <h1>{`Loading: ${Math.floor(props.progression)} %`}</h1>
        </div>
      )}
    </>
  );
}

export default StartScreen;
