import { React, useContext, useEffect } from "react";
import "./UnityModel.scss";
import { UnityContext } from "../context/UnityContext";
import { AppContext } from "../context/AppContext";
import { on, off } from "../utils/Events";
import { INPUT_METHODS, EVENTS, PROFILES, projectSetup } from "../projectSetup";

import { Unity } from "react-unity-webgl";
import ProfileSelector from "./ProfileSelector";

export default function UnityModel(props) {
  const appContext = useContext(AppContext);
  const { toggleLoadingState, sendMessageToUnity } = useContext(UnityContext);

  useEffect(() => {
    on(EVENTS.send_select_signal, handleSendSelectSignal);
    on(EVENTS.unity_initialized, handleUnityInitialized);
    on(EVENTS.materials_updated, handleMaterialsUpdated);
    on(EVENTS.camera_transform_update, handleCameraTransformUpdate);
    return function cleanup() {
      off(EVENTS.send_select_signal, handleSendSelectSignal);
      off(EVENTS.unity_initialized, handleUnityInitialized);
      off(EVENTS.materials_updated, handleMaterialsUpdated);
      off(EVENTS.camera_transform_update, handleCameraTransformUpdate);
    };
  });

  const selectProfile = (e) => {
    console.log(e);
    sendMessageToUnity(INPUT_METHODS.select_profile, {
      profile: e,
    });
    appContext.setProfile(e);
  };

  const handleMaterialsUpdated = (event) => {
    appContext.setQuantities(event.detail.RequiredMaterials);
  };

  const handleSendSelectSignal = (id) => {
    console.log(`Unity | Object ${id} elected`);
  };

  const splines = projectSetup.splines;

  const handleUnityInitialized = () => {
    splines.forEach((spline) => {
        sendMessageToUnity(INPUT_METHODS.open_road, spline);     
      });

    toggleLoadingState();
  };

  const handleCameraTransformUpdate = (event) => {
    appContext.updateCurrentCoordinate({
      x: event.detail.x,
      y: event.detail.y,
      heading: event.detail.rot,
    });
  };

  return (
    <div className="unity-model-wrapper">
      <Unity
        width={props.width}
        className="unity"
        unityProvider={props.unityProvider}
      ></Unity>

      {props.userInterfaceEnabled && (
        <div className="user-interface-wrapper">
          {/* USER INTERFACE / Overlays HERE */}
          <div className="unity-data-overlay">
            <div className="current-profile">
              <div className="round-border">
                <p>{appContext.profile?.slice(-1)}</p>
              </div>
            </div>
            <ProfileSelector
              onSelect={selectProfile}
              name="Select Profile"
              selectedProfile={appContext.profile}
              availableProfiles={PROFILES}
            />
          </div>
        </div>
      )}
    </div>
  );
}
